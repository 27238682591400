import { AxiosResponse } from 'axios'
import { makeUMRequest } from '../utils/request'
import {
  ICreateBulkUserRequest,
  IUpdateUserRequest,
  ISetUserPropertiesRequest,
  BulkUserCreateRequest,
} from '../utils/types'

class UserAPI {
  static async getAllUsers(orgId: number | null, params: any): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'GET',
      url: `orgadmin/organization/${orgId}/users`,
      params,
    })
    return res.data
  }

  static async getUser(orgId: number, userId: number): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'GET',
      url: `orgadmin/organization/${orgId}/user/${userId}`,
    })
    return res.data
  }

  static async createUsersInBulk(
    orgId: number | null,
    data: ICreateBulkUserRequest
  ): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: `orgadmin/users`,
      data,
    })
    return res.data
  }

  static async updateUser(
    orgId: number,
    userId: number,
    data: IUpdateUserRequest
  ): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'PUT',
      url: `orgadmin/organization/${orgId}/user/${userId}`,
      data,
    })
    return res.data
  }

  static async setUserProperties(
    orgId: number,
    userId: number,
    data: ISetUserPropertiesRequest
  ): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: `orgadmin/organization/${orgId}/user/${userId}/properties`,
      data,
    })
    return res.data
  }

  static async setUserExpiry(
    orgId: number,
    userId: number,
    expiry: number
  ): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: `orgadmin/organization/${orgId}/user/${userId}/expireAt`,
      params: {
        expiry,
      },
    })
    return res.data
  }

  static async resetPassword(orgId: number, userId: number): Promise<any> {
    const res: AxiosResponse = await makeUMRequest({
      method: 'POST',
      url: `orgadmin/organization/${orgId}/user/${userId}/password-reset`,
      data: {
        productName: 'selfServiceAdmin',
      },
    })
    return res.data
  }
}

export interface UserCreateRequestV2 {
  userName: string
  email: string
  firstName?: string
  middleName?: string
  lastName?: string
  sendEmail?: boolean
  reference?: string
  mfaTypeId?: number
  orgId?: number
  isShadowUser?: boolean
  licenseId?: number
}

export enum UserCreationStatus {
  PENDING = 'PENDING',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
}

export interface UserCreateResponseV2 {
  userId?: number
  email: string
  status: UserCreationStatus
  signUpLink?: string
}

export async function createUserV2(
  request: UserCreateRequestV2
): Promise<UserCreateResponseV2> {
  const res: AxiosResponse = await makeUMRequest({
    method: 'POST',
    url: `orgadmin/v2/user`,
    data: request,
  })
  return res.data
}

export interface V2BulkUserCreateResponse {
  orgId: number
  licenseId: number
  createdUsers: UserCreateResponseV2[]
  duplicateUsers: string[]
  invalidUsers: Record<string, string>
}

export async function createBulkUsersV2(
  request: BulkUserCreateRequest
): Promise<V2BulkUserCreateResponse> {
  const res: AxiosResponse = await makeUMRequest({
    method: 'POST',
    url: `orgadmin/v2/users`,
    data: request,
  })
  return res.data
}

export default UserAPI
